import socket from '../'
import socketTest from '../socket_test'

export default [
  {
    path: '/socket',
    name: 'socket',
    component: socket
  },
  {
    path: '/socket_test',
    name: 'socket_test',
    component: socketTest
  }
]
